import React from 'react';
import PropTypes from 'prop-types';
import { SessionProvider } from 'next-auth/react';

import { initSentry } from 'utils/sentry';
initSentry();

import 'styles/globals.css';

function AdminApp({ Component, pageProps, err }) {
	const { session } = pageProps;

	return (
		<SessionProvider session={session}>
			{/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
			<Component {...pageProps} err={err} />
		</SessionProvider>
	);
}

AdminApp.propTypes = {
	Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	pageProps: PropTypes.object.isRequired,
	err: PropTypes.any
};

export default AdminApp;
